
import { dash } from "@/filters/utils";
import {
  ReportFilter,
  ReportState,
  ReportTypes,
} from "@/store/modules/reports/reports.types";
import { Component, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { namespace } from "vuex-class";

const Ticket = namespace(ReportTypes.MODULE);

@Component({
  components: {
    IDatePicker: () => import("@/components/utils/IDatePicker.vue"),
    ListTickets: () => import("@/components/ticket/ListTickets.vue"),
    ProductSelect: () => import("@/components/product/ProductSelect.vue"),
    CategorySelect: () => import("@/components/category/CategorySelect.vue"),
    UserSelect: () => import("@/components/user/UserSelect.vue"),
    PrioritySelect: () => import("@/components/priority/PrioritySelect.vue"),
    SiteSelect: () => import("@/components/site/SiteSelect.vue"),
  },
  filters: {
    dash,
  },
})
export default class GeneralReportsView extends Vue {
  public reportFilter: ReportFilter = {};

  @Ticket.Action(ReportTypes.LOAD_TICKET_REPORTS)
  public getReportTickets!: (filter: ReportFilter) => void;

  @Ticket.Action(ReportTypes.DOWNLOAD_CSV_TICKET_REPORTS)
  public downloadCsvReportTickets!: (filter: ReportFilter) => void;

  @Ticket.State(ReportTypes.TICKET_REPORT_FILTER)
  public reportTicketsFilter!: ReportFilter;

  @Ticket.State(ReportTypes.TICKET_REPORTS)
  public reportTickets!: ReportState;

  @Ticket.State(ReportTypes.DOWNLOADING_CSV_TICKET_REPORTS)
  public downloadingReport!: boolean;

  public headers: DataTableHeader[] = [
    {
      text: "#CASE NUMBER",
      sortable: false,
      value: "caseNumber",
      cellClass:
        "primary--text text--darken-2 font-weight-medium text-uppercase",
    },
    {
      text: "ISSUE",
      value: "issue",
      cellClass: "grey--text text--darken-2 text-uppercase",
    },
    {
      text: "ASSIGNED TO",
      value: "assignee",
      sortable: false,
      cellClass: "grey--text text--darken-2 text-uppercase",
    },
    {
      text: "SLA(HOURS)",
      value: "sla",
      sortable: false,
      cellClass: "grey--text text--darken-2 text-uppercase",
    },
    {
      text: "REPORT DATE",
      value: "reportingDate",
      sortable: false,
      cellClass: "grey--text text--darken-2",
    },
    {
      text: "PRIORITY",
      value: "priority",
      sortable: false,
      cellClass: "grey--text text--darken-2",
    },
    {
      text: "STATUS",
      value: "status",
      sortable: false,
      cellClass: "grey--text text--darken-2",
    },
  ];

  loadTickets(force = false): void {
    this.reportFilter.force = force;
    this.getReportTickets(this.reportFilter);
  }

  downloadCsvTickets(): void {
    this.downloadCsvReportTickets(this.reportFilter);
  }

  mounted(): void {
    this.reportFilter = this.reportTicketsFilter;

    this.loadTickets();
  }
}
